import React from 'react'
import Banner from '../../Components/reusable/Banner'
import Layout from '../../Components/reusable/Layout'
import MyCategory from "../../Components/product/category"
function SubCategory() {
    return (
        <Layout>

            <Banner title="Gears" />
<section className="section my-5 py-5">
<div className="container">
    <div className="row my-5">
 
 
        <MyCategory
        title="AUTOMOTIVE GEARS"
        content="
Offering a qualitative range of Automotive Gears, we are  leading Manufacturer and Supplier from India. With a heavy duty construction and hassle-free movement, our Automotive Gears are made using only top class steel sourced from reputable vendors.
 With a promise of minimizing vibration and noise levels, our Automotive Gear range is provided at a competitive price ."
        next="view"
        link="/category/gears/automotive"
        />

        <MyCategory
        title="AGRICULTURAL GEARS"
        content="We are a prominent Agricultural Gears Manufacturer and Supplier from India. Our Agricultural Gears are manufactured while maintaining high standards of quality to deliver the best to the clients. Our experience in this industry has made us proficient
         with the demands of the clients in this sector and we ensure to provide the Agricultural Gears exactly according to their preference."
        next="view"
        link="/category/gears/agriculture"
        />

        <MyCategory
        title="INDUSTRIAL GEARS"
        content="Designed to be in tune with the latest machines used across various industries, we bring a comprehensive gamut of Industrial Gears. We specialize as Industrial Gear Manufacturers and Suppliers from India, providing high quality Industrial Gears. 
        Used in different machines, these Industrial Gears are being provided in bulk quantities by us at reasonable prices."
        next="view"
        link="/category/gears/industrial"
        />
 


 

        </div>

           </div>
       
       
</section>
        </Layout>
    )
}

export default SubCategory
